import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import progress from 'react-redux-progress/reducer'
import store from 'store'
import { cancelAllRequest, renewCancelToken } from '../services/axios'
import user from './user/reducers'
import userDistributor from './user-distributor/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

// USER ACCESS
import area from './user-access/area/reducers'
import areaInactive from './user-access/area-inactive/reducers'
import region from './user-access/region/reducers'
import regionInactive from './user-access/region-inactive/reducers'
import brandGroup from './user-access/brand-group/reducers'
import brandGroupInactive from './user-access/brand-group-inactive/reducers'
import dashboard from './user-access/dashboard/reducers'
import salesType from './user-access/outlet-sales-type/reducers'
import spgType from './user-access/outlet-spg-type/reducers'
import spgPosition from './user-access/outlet-spg-position/reducers'
import outletGroup from './user-access/outlet-group/reducers'
import outletGroupTemp from './user-access/outlet-group-temp/reducers'
import outletChannel from './user-access/outlet-channel/reducers'
import salesTypeInactive from './user-access/outlet-sales-type-inactive/reducers'
import spgTypeInactive from './user-access/outlet-spg-type-inactive/reducers'
import spgPositionInactive from './user-access/outlet-spg-position-inactive/reducers'
import outletGroupInactive from './user-access/outlet-group-inactive/reducers'
import outletChannelInactive from './user-access/outlet-channel-inactive/reducers'
import categorySub from './user-access/category-sub/reducers'
import categorySubInactive from './user-access/category-sub-inactive/reducers'
import categoryCeo from './user-access/category-ceo/reducers'
import categoryCeoInactive from './user-access/category-ceo-inactive/reducers'
import categoryAccenture from './user-access/category-accenture/reducers'
import categoryAccentureInactive from './user-access/category-accenture-inactive/reducers'
import categoryAcc from './user-access/category-acc/reducers'
import categoryAccInactive from './user-access/category-acc-inactive/reducers'
import categoryStock from './user-access/category-stock/reducers'
import categoryStockInactive from './user-access/category-stock-inactive/reducers'
import categorySubStock from './user-access/category-sub-stock/reducers'
import categorySubStockInactive from './user-access/category-sub-stock-inactive/reducers'
import category from './user-access/category/reducers'
import categoryInactive from './user-access/category-inactive/reducers'
import variant from './user-access/variant/reducers'
import variantInactive from './user-access/variant-inactive/reducers'
import rangeVariant from './user-access/range-variant/reducers'
import rangeVariantInactive from './user-access/range-variant-inactive/reducers'
import rangeMarketing from './user-access/range-marketing/reducers'
import rangeMarketingInactive from './user-access/range-marketing-inactive/reducers'
import areaSub from './user-access/area-sub/reducers'
import areaSubInactive from './user-access/area-sub-inactive/reducers'
import brand from './user-access/brand/reducers'
import brandInactive from './user-access/brand-inactive/reducers'
import brandCategory from './user-access/brand-category/reducers'
import brandBusiness from './user-access/brand-business/reducers'
import categoryVitalis from './user-access/category-vitalis/reducers'
import productRange from './user-access/product-range/reducers'
import productRangeInactive from './user-access/product-range-inactive/reducers'
import productListing from './user-access/product-listing/reducers'
import ar from './user-access/ar/reducers'
import outletActive from './user-access/outlet-active/reducers'
import outletAccess from './user-access/outlet-access/reducers'
import outletInactive from './user-access/outlet-inactive/reducers'
import outletLatLngImage from './user-access/outlet-latlng-image/reducers'
import outletLatLngImageInactive from './user-access/outlet-latlng-image-inactive/reducers'
import outletLatLngImageApproval from './user-access/outlet-latlng-image-approval/reducers'
import outletWithSpg from './user-access/outlet-with-spg-active/reducers'
import outletWithSpgInactive from './user-access/outlet-with-spg-inactive/reducers'
import outletWithSpgApproval from './user-access/outlet-with-spg-approval/reducers'
import outletWithSpgTemp from './user-access/outlet-with-spg-temp/reducers'
import outletPriority from './user-access/outlet-priority/reducers'
import outletPriorityInactive from './user-access/outlet-priority-inactive/reducers'
import outletChannelSub from './user-access/outlet-channel-sub/reducers'
import outletChannelSubInactive from './user-access/outlet-channel-sub-inactive/reducers'
import outletApproval from './user-access/outlet-approval/reducers'
import outletTemp from './user-access/outlet-temp/reducers'
import outletTl from './user-access/outlet-tl/reducers'
import outletTlDeviasi from './user-access/outlet-tl-deviasi/reducers'
import outletTlInactive from './user-access/outlet-tl-inactive/reducers'
import outletTlApproval from './user-access/outlet-tl-approval/reducers'
import outletTlSpv from './user-access/outlet-tl-spv/reducers'
import outletTlSpvDeviasi from './user-access/outlet-tl-spv-deviasi/reducers'
import outletTlSpvInactive from './user-access/outlet-tl-spv-inactive/reducers'
import outletTlSpvApproval from './user-access/outlet-tl-spv-approval/reducers'
import outletSalesSpv from './user-access/outlet-sales-spv/reducers'
import outletSalesSpvDeviasi from './user-access/outlet-sales-spv-deviasi/reducers'
import outletSalesSpvInactive from './user-access/outlet-sales-spv-inactive/reducers'
import outletSalesSpvApproval from './user-access/outlet-sales-spv-approval/reducers'
import outletMd from './user-access/outlet-md/reducers'
import outletMdDeviasi from './user-access/outlet-md-deviasi/reducers'
import outletMdInactive from './user-access/outlet-md-inactive/reducers'
import outletMdApproval from './user-access/outlet-md-approval/reducers'
import outletSpg from './user-access/outlet-spg/reducers'
import outletSpgDeviasi from './user-access/outlet-spg-deviasi/reducers'
import outletSpgInactive from './user-access/outlet-spg-inactive/reducers'
import outletSpgApproval from './user-access/outlet-spg-approval/reducers'
import outletSpgTemp from './user-access/outlet-spg-temp/reducers'
import outletDistDistributor from './user-access/outlet-dist-distributor/reducers'
import outletDist from './user-access/outlet-dist/reducers'
import outletDistAccess from './user-access/outlet-dist-access/reducers'
import outletDistDeviasi from './user-access/outlet-dist-deviasi/reducers'
import outletDistApproval from './user-access/outlet-dist-approval/reducers'
import outletDistBlockApproval from './user-access/outlet-dist-approval-block/reducers'
import outletDistApprovalNew from './user-access/outlet-dist-approval-new/reducers'
import outletDistTemp from './user-access/outlet-dist-temp/reducers'
import outletDistTempNew from './user-access/outlet-dist-temp-new/reducers'
import outletDistBlock from './user-access/outlet-dist-block/reducers'
import product from './user-access/product/reducers'
import schedule from './user-access/schedule/reducers'
import scheduleAccounting from './user-access/schedule-accounting/reducers'
import closingMonth from './user-access/closing-month/reducers'
import closingOutletMonth from './user-access/closing-outlet-month/reducers'
import transDistUploadDaily from './user-access/trans-dist-upload-daily/reducers'
import transDistUploadWeekly from './user-access/trans-dist-upload-weekly/reducers'
import transDistUploadStockDaily from './user-access/trans-dist-upload-stock-daily/reducers'
import transDistUploadStockWeekly from './user-access/trans-dist-upload-stock-weekly/reducers'
import transFinalReport from './user-access/trans-final-report/reducers'
import transFinalReportDistributor from './user-access/trans-final-report-distributor/reducers'
import transFinalReportStock from './user-access/trans-final-report-stock/reducers'
import transFinalReportStockDistributor from './user-access/trans-final-report-stock-distributor/reducers'

import userList from './user-access/user-list/reducers'
import userListInactive from './user-access/user-list-inactive/reducers'
import role from './user-access/role/reducers'
import distributorPrimary from './user-access/distributor-primary/reducers'
import distributorPrimaryDeviasi from './user-access/distributor-primary-deviasi/reducers'
import distributorPrimaryBlock from './user-access/distributor-primary-block/reducers'
import distributorEpm from './user-access/distributor-epm/reducers'
import distributorEpmDeviasi from './user-access/distributor-epm-deviasi/reducers'
import distributorEpmBlock from './user-access/distributor-epm-block/reducers'
import distributor from './user-access/distributor/reducers'
import distributorFile from './distributor-file/reducers'
import distributorApproval from './user-access/distributor-approval/reducers'
import approvalBalanceSummary from './user-access/approval-balance-summary/reducers'
import distributorChild from './user-access/distributor-child/reducers'
import distributorInactive from './user-access/distributor-inactive/reducers'
import distributorChildInactive from './user-access/distributor-child-inactive/reducers'
import position from './user-access/position/reducers'
import productDistDistributor from './user-access/product-dist-distributor/reducers'
import productDist from './user-access/product-dist/reducers'
import productDistBlock from './user-access/product-dist-block/reducers'
import productDistDeviasi from './user-access/product-dist-deviasi/reducers'
import productActive from './user-access/product-active/reducers'
import productDistAccess from './user-access/product-dist-access/reducers'
import productInactive from './user-access/product-inactive/reducers'
import productTemp from './user-access/product-temp/reducers'
import productApproval from './user-access/product-approval/reducers'
import productPrimary from './user-access/product-primary/reducers'
import productPrimaryBlock from './user-access/product-primary-block/reducers'
import productPrimaryDeviasi from './user-access/product-primary-deviasi/reducers'
import productPrimaryInactive from './user-access/product-primary-inactive/reducers'
import productDistApproval from './user-access/product-dist-approval/reducers'
import productDistBlockApproval from './user-access/product-dist-block-approval/reducers'
import productDistTemp from './user-access/product-dist-temp/reducers'
import productTop from './user-access/product-top/reducers'
import productTopInactive from './user-access/product-top-inactive/reducers'
import productTopTemp from './user-access/product-top-temp/reducers'
import productTopMonthly from './user-access/product-top-monthly/reducers'
import productTopMonthlyInactive from './user-access/product-top-monthly-inactive/reducers'
import productMssMonthlyTemp from './user-access/product-mss-monthly-temp/reducers'
import productMssMonthly from './user-access/product-mss-monthly/reducers'
import productMssMonthlyInactive from './user-access/product-mss-monthly-inactive/reducers'
import productPrice from './user-access/product-price/reducers'
import productBundle from './user-access/product-bundle/reducers'
import productBundleInactive from './user-access/product-bundle-inactive/reducers'
import productBundleTemp from './user-access/product-bundle-temp/reducers'
import version from './user-access/version/reducers'
import month from './user-access/month/reducers'
import period from './user-access/period/reducers'
import year from './user-access/year/reducers'
import versionInactive from './user-access/version-inactive/reducers'
import monthInactive from './user-access/month-inactive/reducers'
import periodInactive from './user-access/period-inactive/reducers'
import yearInactive from './user-access/year-inactive/reducers'
import targetPrimarySales from './user-access/target-primary-sales/reducers'
import targetPrimarySalesApproval from './user-access/target-primary-sales-approval/reducers'
import targetPrimarySalesTemp from './user-access/target-primary-sales-temp/reducers'
import targetPrimaryBrand from './user-access/target-primary-brand/reducers'
import targetPrimaryBrandTemp from './user-access/target-primary-brand-temp/reducers'
import targetSecondaryBrand from './user-access/target-secondary-brand/reducers'
import targetSecondaryBrandApproval from './user-access/target-secondary-brand-approval/reducers'
import targetSecondaryBrandTemp from './user-access/target-secondary-brand-temp/reducers'
import targetSecondaryDist from './user-access/target-secondary-dist/reducers'
import targetSecondaryDistApproval from './user-access/target-secondary-dist-approval/reducers'
import targetSecondaryDistTemp from './user-access/target-secondary-dist-temp/reducers'
import forecastTargetSecondarySales from './user-access/forecast-target-secondary-sales/reducers'
import targetSecondarySales from './user-access/target-secondary-sales/reducers'
import targetSecondarySalesApproval from './user-access/target-secondary-sales-approval/reducers'
import targetSecondarySalesTemp from './user-access/target-secondary-sales-temp/reducers'
import targetSecondarySalesCb from './user-access/target-secondary-sales-cb/reducers'
import targetSecondarySalesCbApproval from './user-access/target-secondary-sales-cb-approval/reducers'
import targetSecondarySalesCbTemp from './user-access/target-secondary-sales-cb-temp/reducers'
import targetSecondarySalesRental from './user-access/target-secondary-sales-rental/reducers'
import targetSecondarySalesRentalApproval from './user-access/target-secondary-sales-rental-approval/reducers'
import targetSecondarySalesRentalTemp from './user-access/target-secondary-sales-rental-temp/reducers'
import targetSecondarySalesOutlet from './user-access/target-secondary-sales-outlet/reducers'
import targetSecondarySalesOutletApproval from './user-access/target-secondary-sales-outlet-approval/reducers'
import targetSecondarySalesOutletTemp from './user-access/target-secondary-sales-outlet-temp/reducers'
import targetSecondarySalesSpg from './user-access/target-secondary-sales-spg/reducers'
import targetSecondarySalesSpgApproval from './user-access/target-secondary-sales-spg-approval/reducers'
import targetSecondarySalesSpgTemp from './user-access/target-secondary-sales-spg-temp/reducers'
import targetSecondarySalesLcs from './user-access/target-secondary-sales-lcs/reducers'
import targetSecondarySalesLcsApproval from './user-access/target-secondary-sales-lcs-approval/reducers'
import targetSecondarySalesLcsTemp from './user-access/target-secondary-sales-lcs-temp/reducers'
import targetSecondaryChannelSub from './user-access/target-secondary-channel-sub/reducers'
import targetSecondaryChannelSubApproval from './user-access/target-secondary-channel-sub-approval/reducers'
import targetSecondaryChannelSubTemp from './user-access/target-secondary-channel-sub-temp/reducers'
import certificateGtOnline from './user-access/certificate-gt-online/reducers'
import certificateGtOnlineApproval from './user-access/certificate-gt-online-approval/reducers'
import setting from './user-access/setting/reducers'
import notification from './user-access/notification/reducers'
import permission from './user-access/permission/reducers'
import settingConverter from './user-access/setting-converter/reducers'
import settingPoSummarySalesman from './user-access/setting-po-summary-salesman/reducers'
import transDistUpload from './user-access/trans-dist-upload/reducers'
import transDistUploadStock from './user-access/trans-dist-upload-stock/reducers'
import transDistStatusCube from './user-access/trans-dist-status-cube/reducers'
import transDistStatusCubeMmaMax from './user-access/trans-dist-status-cube-mma-mmax/reducers'
import transDistStatusCubeEwom from './user-access/trans-dist-status-cube-ewom/reducers'
import transDistStatusDiscount from './user-access/trans-dist-status-discount/reducers'
import convertUnion from './user-access/convert-union/reducers'
import convertUnionStock from './user-access/convert-union-stock/reducers'
import transDistUploadDraft from './user-access/trans-dist-upload-draft/reducers'
import transDistUploadDraftDistributor from './user-access/trans-dist-upload-draft-distributor/reducers'
import transDistUploadStockDraft from './user-access/trans-dist-upload-stock-draft/reducers'
import transDistUploadStockDraftDistributor from './user-access/trans-dist-upload-stock-draft-distributor/reducers'
import uncombine from './user-access/uncombine/reducers'
import combine from './user-access/combine/reducers'
import uploadDetail from './user-access/data-upload-detail/reducers'
import uploadDetailStock from './user-access/data-upload-detail-stock/reducers'
import listSales from './user-access/list-sales/reducers'

import reportMss from './user-access/report-mss/reducers'
import reportPrimarySales from './user-access/report-primary-sales/reducers'
import reportPrimarySalesByBrand from './user-access/report-primary-sales-by-brand/reducers'
import reportWeeklyPrimarySales from './user-access/report-weekly-primary-sales/reducers'
import reportDoByDaily from './user-access/report-do-by-daily/reducers'
import reportDo from './user-access/report-do/reducers'
import reportSoVsDo from './user-access/report-so-vs-do/reducers'
import reportAchByBrand from './user-access/report-ach-by-brand/reducers'
import reportTargetAchPeriodByBrand from './user-access/report-target-ach-period-by-brand/reducers'
import reportOpscCosmoByOutletAndRange from './user-access/report-opsc-cosmo-by-outlet-and-range/reducers'
import reportTopParetto from './user-access/report-top-paretto/reducers'
import reportNoTransThreeMma from './user-access/report-no-trans-three-mma/reducers'
import reportNoTransThreeMmaOutletOnly from './user-access/report-no-trans-three-mma-outlet-only/reducers'
import reportNoTransFourMmaOutlet from './user-access/report-no-trans-four-mma-outlet/reducers'
import reportNoTransTwoMmaOutlet from './user-access/report-no-trans-two-mma-outlet/reducers'
import reportNooTracking from './user-access/report-noo-tracking/reducers'
import reportProductivityByMaster from './user-access/report-productivity-by-master/reducers'
import reportTimeSeries from './user-access/report-time-series/reducers'
import reportTimeSeriesPromotor from './user-access/report-time-series-promotor/reducers'
import reportHistoryOutlet from './user-access/report-history-outlet/reducers'
import reportProductTop100 from './user-access/report-product-top-100/reducers'
import reportProductTop100And50 from './user-access/report-product-top-100-and-50/reducers'
import reportIncentiveAss from './user-access/report-incentive-ass/reducers'
import reportIncentiveAsm from './user-access/report-incentive-asm/reducers'
import reportIncentiveRsm from './user-access/report-incentive-rsm/reducers'
import reportIncentiveRps from './user-access/report-incentive-rps/reducers'
import reportIncentiveQuarterAss from './user-access/report-incentive-quarter-ass/reducers'
import reportIncentiveQuarterAsm from './user-access/report-incentive-quarter-asm/reducers'
import reportIncentiveQuarterRsm from './user-access/report-incentive-quarter-rsm/reducers'
import reportIncentiveQuarterRps from './user-access/report-incentive-quarter-rps/reducers'
import reportIncentiveTlSpv from './user-access/report-incentive-tl-spv/reducers'
import reportIncentiveTl from './user-access/report-incentive-tl/reducers'
import reportIncentiveGroupPromotorManager from './user-access/report-incentive-group-promotor-manager/reducers'
import reportIncentiveSpg from './user-access/report-incentive-spg/reducers'
import reportIncentiveSpv from './user-access/report-incentive-spv/reducers'
import reportIncentiveHomt from './user-access/report-incentive-homt/reducers'
import reportIncentiveNkam from './user-access/report-incentive-nkam/reducers'
import reportIncentiveKam from './user-access/report-incentive-kam/reducers'
import reportIncentiveSos from './user-access/report-incentive-sos/reducers'
import reportIncentiveSom from './user-access/report-incentive-som/reducers'
import reportIncentiveSoe from './user-access/report-incentive-soe/reducers'
import reportIncentiveQuarterSom from './user-access/report-incentive-quarter-som/reducers'
import reportIncentiveQuarterSoe from './user-access/report-incentive-quarter-soe/reducers'
import reportIncentiveSalesman from './user-access/report-incentive-salesman/reducers'
import reportIncentiveDistributor from './user-access/report-incentive-distributor/reducers'
import reportDailyBrandSecSalesSummary from './user-access/report-daily-brand-sec-sales-summary/reducers'
import reportSecSalesAndProductivity from './user-access/report-sec-sales-and-productivity/reducers'
import reportKaSecSalesAndProductivity from './user-access/report-ka-sec-sales-and-productivity/reducers'
import reportDoByDistributor from './user-access/report-do-by-distributor/reducers'
import reportSecSalesByBrand from './user-access/report-sec-sales-by-brand/reducers'
import reportProductivitySfaVsStar from './user-access/report-productivity-sfa-vs-star/reducers'
import reportAchByTypeChannel from './user-access/report-ach-by-type-channel/reducers'
import reportAoByTypeChannel from './user-access/report-ao-by-type-channel/reducers'
import reportAchBySalesman from './user-access/report-ach-by-salesman/reducers'
import reportBrandBySalesman from './user-access/report-brand-by-salesman/reducers'
import reportEcBySalesman from './user-access/report-ec-by-salesman/reducers'
import reportAoBySalesman from './user-access/report-ao-by-salesman/reducers'
import reportTypeChannelBySalesman from './user-access/report-type-channel-by-salesman/reducers'
import reportBusinessReview from './user-access/report-business-review/reducers'
import reportMr from './user-access/report-mr/reducers'
import reportBalanceSummary from './user-access/report-balance-summary/reducers'
import reportSalesSecondaryMtd from './user-access/report-sales-secondary-mtd/reducers'
import reportSalesSecondaryMtdByBrand from './user-access/report-sales-secondary-mtd-by-brand/reducers'
import targetIncentiveMss from './user-access/target-incentive-mss/reducers'
import targetTlsdLopSales from './user-access/target-tlsd-lop-sales/reducers'
import targetRoSales from './user-access/target-ro-sales/reducers'
import targetDayOfWork from './user-access/target-day-of-work/reducers'
import mensaAreaSub from './user-access/mensa-area-sub/reducers'
import epmAreaSub from './user-access/epm-area-sub/reducers'
import shippingPoint from './user-access/shipping-point/reducers'
import shippingPointInactive from './user-access/shipping-point-inactive/reducers'
import business from './user-access/business/reducers'
import claimPromoEpm from './user-access/claim-promo-epm/reducers'
import dailySend from './user-access/daily-send/reducers'
import detailDailySend from './user-access/detail-daily-send/reducers'
import stock from './user-access/stock/reducers'
import reportEwom from './user-access/report-ewom/reducers'
import reportRom from './user-access/report-rom/reducers'
import reportMdJagoJualan from './user-access/report-md-jago-jualan/reducers'
import reportRecapSalesmanEwom from './user-access/report-recap-salesman-ewom/reducers'
import reportRecapMdSpgEwom from './user-access/report-recap-md-spg-ewom/reducers'
import reportBasedEstimate from './user-access/report-based-estimate/reducers'
import reportBeAss from './user-access/report-be-ass/reducers'
import outletSalesPermission from './user-access/outlet-sales-permission/reducers'
import outletSalesActive from './user-access/outlet-sales-active/reducers'
import outletSalesInactive from './user-access/outlet-sales-inactive/reducers'
import outletSalesDeviasi from './user-access/outlet-sales-deviasi/reducers'
import outletSalesTemp from './user-access/outlet-sales-temp/reducers'
import outletSalesApproval from './user-access/outlet-sales-approval/reducers'
import transDistUploadDistributor from './user-access/trans-dist-upload-distributor/reducers'
import transDistUploadStockDistributor from './user-access/trans-dist-upload-stock-distributor/reducers'

// report sfa
import reportCall from './user-access/report-sfa/report-call/reducers'
import reportAbsenPromotor from './user-access/report-sfa/report-absen-promotor/reducers'
import reportAbsen from './user-access/report-sfa/report-absen/reducers'
import reportPosmDetail from './user-access/report-sfa/report-posm-detail/reducers'
import reportPosmSummary from './user-access/report-sfa/report-posm-summary/reducers'
import reportAccurationPjp from './user-access/report-sfa/report-accuration-pjp/reducers'
import reportCompetitor from './user-access/report-sfa/report-competitor/reducers'
import reportActitivyDailyMd from './user-access/report-sfa/report-activity-daily-md/reducers'
import reportActitivyMerchandising from './user-access/report-sfa/report-activity-merchandising/reducers'
import reportActitivyDailySfa from './user-access/report-sfa/report-activity-daily-sfa/reducers'
import reportAbsenSfa from './user-access/report-sfa/report-absen-sfa/reducers'
import reportProductivitySfa from './user-access/report-sfa/report-productivity-sfa/reducers'
import reportJourneSalesmanSfa from './user-access/report-sfa/report-journey-salesman-sfa/reducers'
import reportPoSummarySalesman from './user-access/report-sfa/report-po-summary-salesman/reducers'
import reportPoDetailSalesman from './user-access/report-sfa/report-po-detail-salesman/reducers'
import reportDisplaySalesman from './user-access/report-sfa/report-display-salesman/reducers'
import reportActitivyDailySpv from './user-access/report-sfa/report-activity-daily-spv/reducers'
import reportAbsenSpv from './user-access/report-sfa/report-absen-spv/reducers'
import reportIncentiveMd from './user-access/report-sfa/report-incentive-md/reducers'
import reportMail from './user-access/report-mail/reducers'

// dashboard chart
import dashboardChart from './user-access/dashboard-chart/reducers'

// dashboard enseval chart
import dashboardEnsevalChart from './user-access/dashboard-enseval-chart/reducers'

// dashboard sfa
import dashboardSfaManagement from './user-access/dashboard-sfa/management/reducers'
import dashboardSfaMd from './user-access/dashboard-sfa/md/reducers'
import dashboardSfaSales from './user-access/dashboard-sfa/sales/reducers'
import dashboardSfaRo from './user-access/dashboard-sfa/ro/reducers'

// dashboard user
import dashboardUser from './user-access/dashboard-user/reducers'

// dashboard kindis
import dashboardKindis from './user-access/dashboard-kindis/reducers'

// master sfa
import sfaProductTop from './user-access/master-sfa/product/product-top/reducers'
import sfaProductTopInactive from './user-access/master-sfa/product/product-top-inactive/reducers'
import sfaProductTopTemp from './user-access/master-sfa/product/product-top-temp/reducers'
import banner from './user-access/master-sfa/banner/reducers'
import pkModule from './user-access/master-sfa/pk-module/reducers'
import tradePromo from './user-access/master-sfa/trade-promo/reducers'
import bannerInactive from './user-access/master-sfa/banner-inactive/reducers'
import pkModuleInactive from './user-access/master-sfa/pk-module-inactive/reducers'
import tradePromoInactive from './user-access/master-sfa/trade-promo-inactive/reducers'
import programRank from './user-access/master-sfa/program/rank/reducers'
import programRegular from './user-access/master-sfa/program/regular/reducers'
import programRental from './user-access/master-sfa/program/rental/reducers'

import consumerChannelProductBundle from './user-access/master-sfa/consumer/channel-product-bundle/reducers'
import consumerChannelProductBundleTemp from './user-access/master-sfa/consumer/channel-product-bundle-temp/reducers'
import consumerChannelProduct from './user-access/master-sfa/consumer/channel-product/reducers'
import consumerChannelProductTemp from './user-access/master-sfa/consumer/channel-product-temp/reducers'
import consumerChannelRange from './user-access/master-sfa/consumer/channel-range/reducers'
import consumerChannelRangeTemp from './user-access/master-sfa/consumer/channel-range-temp/reducers'
import consumerChannelBrand from './user-access/master-sfa/consumer/channel-brand/reducers'
import consumerChannelBrandTemp from './user-access/master-sfa/consumer/channel-brand-temp/reducers'
import consumerChannel from './user-access/master-sfa/consumer/channel/reducers'
import consumerChannelTemp from './user-access/master-sfa/consumer/channel-temp/reducers'

import consumerDistributorProductBundle from './user-access/master-sfa/consumer/distributor-product-bundle/reducers'
import consumerDistributorProductBundleTemp from './user-access/master-sfa/consumer/distributor-product-bundle-temp/reducers'
import consumerDistributorProduct from './user-access/master-sfa/consumer/distributor-product/reducers'
import consumerDistributorProductTemp from './user-access/master-sfa/consumer/distributor-product-temp/reducers'
import consumerDistributorRange from './user-access/master-sfa/consumer/distributor-range/reducers'
import consumerDistributorRangeTemp from './user-access/master-sfa/consumer/distributor-range-temp/reducers'
import consumerDistributorBrand from './user-access/master-sfa/consumer/distributor-brand/reducers'
import consumerDistributorBrandTemp from './user-access/master-sfa/consumer/distributor-brand-temp/reducers'
import consumerDistributor from './user-access/master-sfa/consumer/distributor/reducers'
import consumerDistributorTemp from './user-access/master-sfa/consumer/distributor-temp/reducers'

import consumerOutletProductBundle from './user-access/master-sfa/consumer/outlet-product-bundle/reducers'
import consumerOutletProductBundleTemp from './user-access/master-sfa/consumer/outlet-product-bundle-temp/reducers'
import consumerOutletProduct from './user-access/master-sfa/consumer/outlet-product/reducers'
import consumerOutletProductTemp from './user-access/master-sfa/consumer/outlet-product-temp/reducers'
import consumerOutletRange from './user-access/master-sfa/consumer/outlet-range/reducers'
import consumerOutletRangeTemp from './user-access/master-sfa/consumer/outlet-range-temp/reducers'
import consumerOutletBrand from './user-access/master-sfa/consumer/outlet-brand/reducers'
import consumerOutletBrandTemp from './user-access/master-sfa/consumer/outlet-brand-temp/reducers'
import consumerOutlet from './user-access/master-sfa/consumer/outlet/reducers'
import consumerOutletTemp from './user-access/master-sfa/consumer/outlet-temp/reducers'

// quiz
import quiz from './user-access/master-sfa/quiz/reducers'
import quizInactive from './user-access/master-sfa/quiz-inactive/reducers'
import quizTemp from './user-access/master-sfa/quiz-temp/reducers'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    userDistributor,
    menu,
    settings,
    // USER ACCESS
    area,
    areaInactive,
    region,
    regionInactive,
    brandGroup,
    brandGroupInactive,
    dashboard,
    salesType,
    spgType,
    spgPosition,
    outletGroup,
    outletGroupTemp,
    outletChannel,
    salesTypeInactive,
    spgTypeInactive,
    spgPositionInactive,
    outletGroupInactive,
    outletChannelInactive,
    categorySub,
    categorySubInactive,
    categoryCeo,
    categoryCeoInactive,
    categoryAccenture,
    categoryAccentureInactive,
    categoryAcc,
    categoryAccInactive,
    categoryStock,
    categoryStockInactive,
    categorySubStock,
    categorySubStockInactive,
    category,
    categoryInactive,
    variant,
    variantInactive,
    rangeVariant,
    rangeVariantInactive,
    rangeMarketing,
    rangeMarketingInactive,
    settingConverter,
    settingPoSummarySalesman,
    areaSub,
    areaSubInactive,
    brand,
    brandInactive,
    brandCategory,
    brandBusiness,
    categoryVitalis,
    productRange,
    productRangeInactive,
    productListing,
    ar,
    outletChannelSub,
    outletChannelSubInactive,
    userList,
    userListInactive,
    listSales,
    distributorPrimary,
    distributorPrimaryDeviasi,
    distributorPrimaryBlock,
    distributorEpm,
    distributorEpmDeviasi,
    distributorEpmBlock,
    distributor,
    distributorFile,
    distributorApproval,
    distributorInactive,
    distributorChild,
    distributorChildInactive,
    approvalBalanceSummary,
    position,
    productDistDistributor,
    productDist,
    productDistAccess,
    productDistBlock,
    productDistDeviasi,
    productActive,
    product,
    productInactive,
    productTemp,
    productDistTemp,
    productTop,
    productTopInactive,
    productTopTemp,
    productTopMonthly,
    productTopMonthlyInactive,
    productMssMonthlyTemp,
    productMssMonthly,
    productMssMonthlyInactive,
    productPrice,
    productBundle,
    productBundleInactive,
    productBundleTemp,
    productPrimary,
    productPrimaryBlock,
    productPrimaryDeviasi,
    productPrimaryInactive,
    productApproval,
    productDistApproval,
    productDistBlockApproval,
    outletActive,
    outletAccess,
    outletInactive,
    outletLatLngImage,
    outletLatLngImageInactive,
    outletLatLngImageApproval,
    outletWithSpg,
    outletWithSpgInactive,
    outletWithSpgApproval,
    outletWithSpgTemp,
    outletPriority,
    outletPriorityInactive,
    outletTl,
    outletTlDeviasi,
    outletTlInactive,
    outletTlApproval,
    outletTlSpv,
    outletTlSpvDeviasi,
    outletTlSpvInactive,
    outletTlSpvApproval,
    outletSalesSpv,
    outletSalesSpvDeviasi,
    outletSalesSpvInactive,
    outletSalesSpvApproval,
    outletMd,
    outletMdDeviasi,
    outletMdInactive,
    outletMdApproval,
    outletSpg,
    outletSpgDeviasi,
    outletSpgInactive,
    outletSpgApproval,
    outletSpgTemp,
    outletDistDistributor,
    outletDist,
    outletDistTemp,
    outletDistTempNew,
    outletDistAccess,
    outletDistApproval,
    outletDistBlockApproval,
    outletDistApprovalNew,
    outletDistDeviasi,
    outletDistBlock,
    outletTemp,
    outletApproval,
    version,
    month,
    period,
    year,
    versionInactive,
    monthInactive,
    periodInactive,
    yearInactive,
    closingMonth,
    closingOutletMonth,
    targetPrimarySales,
    targetPrimarySalesApproval,
    targetPrimarySalesTemp,
    targetPrimaryBrand,
    targetPrimaryBrandTemp,
    targetSecondaryBrand,
    targetSecondaryBrandApproval,
    targetSecondaryBrandTemp,
    targetSecondaryDist,
    targetSecondaryDistApproval,
    targetSecondaryDistTemp,
    forecastTargetSecondarySales,
    targetSecondarySales,
    targetSecondarySalesApproval,
    targetSecondarySalesTemp,
    targetSecondarySalesCb,
    targetSecondarySalesCbApproval,
    targetSecondarySalesCbTemp,
    targetSecondarySalesRental,
    targetSecondarySalesRentalApproval,
    targetSecondarySalesRentalTemp,
    targetSecondarySalesOutlet,
    targetSecondarySalesOutletApproval,
    targetSecondarySalesOutletTemp,
    targetSecondarySalesSpg,
    targetSecondarySalesSpgApproval,
    targetSecondarySalesSpgTemp,
    targetSecondarySalesLcs,
    targetSecondarySalesLcsApproval,
    targetSecondarySalesLcsTemp,
    targetSecondaryChannelSub,
    targetSecondaryChannelSubApproval,
    targetSecondaryChannelSubTemp,
    certificateGtOnline,
    certificateGtOnlineApproval,
    setting,
    notification,
    permission,
    schedule,
    scheduleAccounting,
    transDistUpload,
    transDistUploadStock,
    transDistUploadDraft,
    transDistUploadDraftDistributor,
    transDistUploadStockDraft,
    transDistUploadStockDraftDistributor,
    role,
    uncombine,
    combine,
    uploadDetail,
    uploadDetailStock,
    transDistUploadDaily,
    transDistUploadStockDaily,
    transDistUploadWeekly,
    transDistUploadStockWeekly,
    transFinalReport,
    transFinalReportDistributor,
    transFinalReportStock,
    transFinalReportStockDistributor,
    transDistStatusCube,
    transDistStatusCubeMmaMax,
    transDistStatusCubeEwom,
    transDistStatusDiscount,
    convertUnion,
    convertUnionStock,
    reportMss,
    reportPrimarySales,
    reportPrimarySalesByBrand,
    reportWeeklyPrimarySales,
    reportDoByDaily,
    reportDo,
    reportSoVsDo,
    reportAchByBrand,
    reportTargetAchPeriodByBrand,
    reportOpscCosmoByOutletAndRange,
    reportTopParetto,
    reportNoTransThreeMma,
    reportNoTransThreeMmaOutletOnly,
    reportNoTransFourMmaOutlet,
    reportNoTransTwoMmaOutlet,
    reportNooTracking,
    reportProductivityByMaster,
    reportTimeSeries,
    reportTimeSeriesPromotor,
    reportHistoryOutlet,
    reportProductTop100,
    reportProductTop100And50,
    reportIncentiveAss,
    reportIncentiveAsm,
    reportIncentiveRsm,
    reportIncentiveRps,
    reportIncentiveQuarterAss,
    reportIncentiveQuarterAsm,
    reportIncentiveQuarterRsm,
    reportIncentiveQuarterRps,
    reportIncentiveTlSpv,
    reportIncentiveTl,
    reportIncentiveSpg,
    reportIncentiveSpv,
    reportIncentiveGroupPromotorManager,
    reportIncentiveHomt,
    reportIncentiveNkam,
    reportIncentiveKam,
    reportIncentiveSos,
    reportIncentiveSom,
    reportIncentiveSoe,
    reportIncentiveQuarterSom,
    reportIncentiveQuarterSoe,
    reportIncentiveSalesman,
    reportIncentiveDistributor,
    reportProductivitySfaVsStar,
    reportDailyBrandSecSalesSummary,
    reportSecSalesAndProductivity,
    reportKaSecSalesAndProductivity,
    reportDoByDistributor,
    reportSecSalesByBrand,
    reportAchByTypeChannel,
    reportAoByTypeChannel,
    reportAchBySalesman,
    reportBrandBySalesman,
    reportEcBySalesman,
    reportAoBySalesman,
    reportMr,
    reportTypeChannelBySalesman,
    reportBusinessReview,
    reportBalanceSummary,
    reportSalesSecondaryMtd,
    reportSalesSecondaryMtdByBrand,
    targetIncentiveMss,
    targetTlsdLopSales,
    targetDayOfWork,
    targetRoSales,
    mensaAreaSub,
    epmAreaSub,
    business,
    shippingPoint,
    shippingPointInactive,
    claimPromoEpm,
    dailySend,
    detailDailySend,
    stock,
    reportEwom,
    reportRom,
    reportMdJagoJualan,
    reportRecapSalesmanEwom,
    reportRecapMdSpgEwom,
    reportBasedEstimate,
    reportBeAss,
    outletSalesPermission,
    outletSalesActive,
    outletSalesInactive,
    outletSalesDeviasi,
    outletSalesTemp,
    outletSalesApproval,
    transDistUploadDistributor,
    transDistUploadStockDistributor,
    progress,
    reportCall,
    reportAbsenPromotor,
    reportAbsen,
    reportPosmDetail,
    reportPosmSummary,
    reportAccurationPjp,
    reportCompetitor,
    reportActitivyDailyMd,
    reportActitivyMerchandising,
    reportActitivyDailySfa,
    reportAbsenSfa,
    reportProductivitySfa,
    reportJourneSalesmanSfa,
    reportPoSummarySalesman,
    reportPoDetailSalesman,
    reportDisplaySalesman,
    reportActitivyDailySpv,
    reportAbsenSpv,
    reportIncentiveMd,
    reportMail,
    dashboardEnsevalChart,
    dashboardChart,
    dashboardSfaManagement,
    dashboardSfaMd,
    dashboardSfaSales,
    dashboardSfaRo,
    dashboardUser,
    dashboardKindis,
    sfaProductTop,
    sfaProductTopInactive,
    sfaProductTopTemp,
    banner,
    pkModule,
    tradePromo,
    bannerInactive,
    pkModuleInactive,
    tradePromoInactive,
    programRank,
    programRegular,
    programRental,
    consumerChannelProduct,
    consumerChannelRange,
    consumerChannelBrand,
    consumerChannelProductBundle,
    consumerChannelProductBundleTemp,
    consumerChannel,
    consumerDistributorProduct,
    consumerDistributorRange,
    consumerDistributorBrand,
    consumerDistributorProductBundle,
    consumerDistributor,
    consumerOutletProductBundle,
    consumerOutletProduct,
    consumerOutletRange,
    consumerOutletBrand,
    consumerOutlet,
    quiz,
    quizInactive,
    quizTemp,
    consumerDistributorProductBundleTemp,
    consumerChannelProductTemp,
    consumerChannelRangeTemp,
    consumerChannelBrandTemp,
    consumerChannelTemp,
    consumerDistributorProductTemp,
    consumerDistributorRangeTemp,
    consumerDistributorBrandTemp,
    consumerDistributorTemp,
    consumerOutletProductBundleTemp,
    consumerOutletProductTemp,
    consumerOutletRangeTemp,
    consumerOutletBrandTemp,
    consumerOutletTemp,
  })
  return (state, action) => {
    if (
      action.type === 'user/UNAUTHENTICATED' ||
      action.type === 'user-distributor/UNAUTHENTICATED'
    ) {
      const { router, menu: menuState, settings: settingsState } = state
      state = { router, menu: menuState, settings: settingsState }
      store.remove('token')
      store.remove('authtype')
      cancelAllRequest()
      renewCancelToken()
    } else if (action.type === 'user/AUTHENTICATED') {
      const { router, user: userState, menu: menuState, settings: settingsState } = state
      state = { router, user: userState, menu: menuState, settings: settingsState }
    } else if (action.type === 'user-distributor/AUTHENTICATED') {
      const { router, userDistributor: userState, menu: menuState, settings: settingsState } = state
      state = { router, userDistributor: userState, menu: menuState, settings: settingsState }
    }
    return appReducer(state, action)
  }
}
