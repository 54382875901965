const actions = {
  SET_STATE: 'outlet-with-spg-active/SET_STATE',
  SEARCH: 'outlet-with-spg-active/SEARCH',
  CANCEL: 'outlet-with-spg-active/CANCEL',
  EDIT: 'outlet-with-spg-active/EDIT',
  UPDATE: 'outlet-with-spg-active/UPDATE',
  FILTER: 'outlet-with-spg-active/FILTER',
  UPLOAD_FILE: 'outlet-with-spg-active/UPLOAD_FILE',
  REMINDER: 'outlet-with-spg-active/REMINDER',
}

export default actions
