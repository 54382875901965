import actions from './actions'

const initialState = {
  progressProduct: null,
  progressProductDist: null,
  progressProductDistBlock: null,
  progressOutlet: null,
  progressOutletLatLngImage: null,
  progressOutletWithSpg: null,
  progressOutletDist: null,
  progressOutletDistBlock: null,
  progressOutletSales: null,
  progressOutletSalesSpv: null,
  progressOutletSpg: null,
  progressOutletMd: null,
  progressOutletTl: null,
  progressOutletTlSpv: null,
  progressTargetPrimarySales: null,
  progressTargetSales: null,
  progressTargetChannelSub: null,
  progressTargetBrand: null,
  progressTargetDist: null,
  progressTargetSalesLcs: null,
  progressTargetSalesCb: null,
  progressTargetSalesRental: null,
  progressTargetSalesOutlet: null,
  progressTargetSalesSpg: null,
  progressBalanceSummary: null,
  progressDistributor: null,
  progressCertificateGtOnline: null,

  errorProgressProduct: null,
  errorProgressProductDist: null,
  errorProgressProductDistBlock: null,
  errorProgressOutlet: null,
  errorProgressOutletLatLngImage: null,
  errorProgressOutletWithSpg: null,
  errorProgressOutletDist: null,
  errorProgressOutletDistBlock: null,
  errorProgressOutletSales: null,
  errorProgressOutletSalesSpv: null,
  errorProgressOutletSpg: null,
  errorProgressOutletMd: null,
  errorProgressOutletTl: null,
  errorProgressOutletTlSpv: null,
  errorProgressTargetPrimarySales: null,
  errorProgressTargetSales: null,
  errorProgressTargetBrand: null,
  errorProgressTargetDist: null,
  errorProgressTargetChannelSub: null,
  errorProgressTargetSalesLcs: null,
  errorProgressTargetSalesCb: null,
  errorProgressTargetSalesRental: null,
  errorProgressTargetSalesOutlet: null,
  errorProgressTargetSalesSpg: null,
  errorBalanceSummary: null,
  errorDistributor: null,
  errorProgressCertificateGtOnline: null,

  loadingProgressProduct: false,
  loadingProgressProductDist: false,
  loadingProgressProductDistBlock: false,
  loadingProgressOutlet: false,
  loadingProgressOutletLatLngImage: false,
  loadingProgressOutletWithSpg: false,
  loadingProgressOutletDist: false,
  loadingProgressOutletDistBlock: false,
  loadingProgressOutletSales: false,
  loadingProgressOutletSalesSpv: false,
  loadingProgressOutletSpg: false,
  loadingProgressOutletMd: false,
  loadingProgressOutletTl: false,
  loadingProgressOutletTlSpv: false,
  loadingProgressTargetPrimarySales: false,
  loadingProgressTargetSales: false,
  loadingProgressTargetBrand: false,
  loadingProgressTargetDist: false,
  loadingProgressTargetChannelSub: false,
  loadingProgressTargetSalesLcs: false,
  loadingProgressTargetSalesCb: false,
  loadingProgressTargetSalesRental: false,
  loadingProgressTargetSalesOutlet: false,
  loadingProgressTargetSalesSpg: false,
  loadingProgressBalanceSummary: false,
  loadingProgressDistributor: false,
  loadingProgressCertificateGtOnline:false,

  listProduct: [],
  listProductDist: [],
  listProductDistBlock: [],
  listOutlet: [],
  listOutletLatLngImage: [],
  listOutletWithSpg: [],
  listOutletDist: [],
  listOutletDistNew: [],
  listOutletDistBlock: [],
  listOutletSales: [],
  listOutletSalesSpv: [],
  listOutletSpg: [],
  listOutletMd: [],
  listOutletTl: [],
  listOutletTlSpv: [],
  listTargetPrimarySales: [],
  listTargetSales: [],
  listTargetDist: [],
  listTargetBrand: [],
  listTargetChannelSub: [],
  listTargetSalesLcs: [],
  listTargetSalesCb: [],
  listTargetSalesRental: [],
  listTargetSalesOutlet: [],
  listTargetSalesSpg: [],
  listBalanceSummary: [],
  listDistributor: [],
  listCertificateGtOnline: [],

  loadingListProductDist: false,
  loadingListProduct: false,
  loadingListProductDistBlock: false,
  loadingListOutlet: false,
  loadingListOutletLatLngImage: false,
  loadingListOutletWithSpg: false,
  loadingListOutletDist: false,
  loadingListOutletDistNew: false,
  loadingListOutletDistBlock: false,
  loadingListOutletSales: false,
  loadingListOutletSalesSpv: false,
  loadingListOutletSpg: false,
  loadingListOutletMd: false,
  loadingListOutletTl: false,
  loadingListOutletTlSpv: false,
  loadingListTargetPrimarySales: false,
  loadingListTargetDist: false,
  loadingListTargetSales: false,
  loadingListTargetBrand: false,
  loadingListTargetChannelSub: false,
  loadingListTargetSalesLcs: false,
  loadingListTargetSalesCb: false,
  loadingListTargetSalesRental: false,
  loadingListTargetSalesOutlet: false,
  loadingListTargetSalesSpg: false,
  loadingListBalanceSummary: false,
  loadingListDistributor: false,
  loadingListCertificateGtOnline: false,

  loadingOutletFilter: false,
  dataFilterOutlet: null,
  errorFilterOutlet: null,

  errorList: null,
  order: null,
  currentPagination: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },

  errorListNew: null,
  orderNew: null,
  currentPaginationNew: {
    current: 1,
    pageSize: 10,
    position: ['topLeft', 'none'],
    style: {
      marginTop: '8px',
    },
    showSizeChanger: true,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
  },
}

export default function areaReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.RESET_MODAL_PROGRESS:
      return {
        ...state,

        listProduct: [],
        listProductDist: [],
        listProductDistBlock: [],
        listOutlet: [],
        listOutletLatLngImage: [],
        listOutletWithSpg: [],
        listOutletDist: [],
        listOutletDistNew: [],
        listOutletDistBlock: [],
        listOutletSales: [],
        listOutletSalesSpv: [],
        listOutletSpg: [],
        listOutletMd: [],
        listOutletTl: [],
        listOutletTlSpv: [],
        listTargetPrimarySales: [],
        listTargetDist: [],
        listTargetSales: [],
        listTargetBrand: [],
        listTargetChannelSub: [],
        listTargetSalesLcs: [],
        listTargetSalesCb: [],
        listTargetSalesRental: [],
        listTargetSalesOutlet: [],
        listTargetSalesSpg: [],
        listBalanceSummary: [],
        listDistributor: [],
        listCertificateGtOnline: [],

        loadingListProductDist: false,
        loadingListProduct: false,
        loadingListProductDistBlock: false,
        loadingListOutlet: false,
        loadingListOutletLatLngImage: false,
        loadingListOutletWithSpg: false,
        loadingListOutletDist: false,
        loadingListOutletDistNew: false,
        loadingListOutletDistBlock: false,
        loadingListOutletSales: false,
        loadingListOutletSalesSpv: false,
        loadingListOutletSpg: false,
        loadingListOutletMd: false,
        loadingListOutletTl: false,
        loadingListOutletTlSpv: false,
        loadingListTargetPrimarySales: false,
        loadingListTargetDist: false,
        loadingListTargetSales: false,
        loadingListTargetBrand: false,
        loadingListTargetChannelSub: false,
        loadingListTargetSalesLcs: false,
        loadingListTargetSalesCb: false,
        loadingListTargetSalesRental: false,
        loadingListTargetSalesOutlet: false,
        loadingListTargetSalesSpg: false,
        loadingListBalanceSummary: false,
        loadingListDistributor: false,
        loadingListCertificateGtOnline: false,

        loadingOutletFilter: false,
        dataFilterOutlet: null,
        errorFilterOutlet: null,

        errorList: null,
        order: null,
        currentPagination: {
          current: 1,
          pageSize: 10,
          position: ['topLeft', 'none'],
          style: {
            marginTop: '8px',
          },
          showSizeChanger: true,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
        },

        errorListNew: null,
        orderNew: null,
        currentPaginationNew: {
          current: 1,
          pageSize: 10,
          position: ['topLeft', 'none'],
          style: {
            marginTop: '8px',
          },
          showSizeChanger: true,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} data`,
        },
      }
    case actions.CANCEL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
