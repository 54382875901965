import apiClient from 'services/axios'

export async function getOutlet(parameter) {
  return apiClient
    .get('/user/outlet-with-spg/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editOutlet(id) {
  return apiClient
    .get(`/user/outlet-with-spg/${id}/edit`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateOutlet(id, values) {
  return apiClient
    .post(`/user/outlet-with-spg/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resetOutlet(id, values) {
  return apiClient
    .post(`/user/outlet-with-spg/${id}/reset`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterOutlet(parameter = {}) {
  return apiClient
    .get('/user/outlet-with-spg/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/outlet-with-spg-temp/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
    return apiClient
      .post(`/user/outlet-with-spg/reminder-approval`, values)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
